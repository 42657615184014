import * as React from "react";
import { Component } from 'react';
import Layout from '../../components/global/layout.js'
import Content from '../../components/kayla-quimbley/content-kayla-quimbley.js'
import '@fortawesome/fontawesome-free/css/all.css'; 
import '../../sass/app.scss';

class KaylaQuimbley extends Component {
  render() {
    return (
      <>
        <Layout section="deeper-dive" page="kayla-quimbley">
          <Content section="deeper-dive" page="kayla-quimbley" />
        </Layout>
      </>
    )
  }
}

export default KaylaQuimbley;