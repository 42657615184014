import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTML5Video from "../global/video.js";
import BackToTop from "../global/BackToTop.js";
import ClosedCaptions from "file-loader!../../closed-captions/kayla-quimbley.vtt";

class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      VideoSrc: undefined,
      VideoThumb: undefined,
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClick = (video) => {
    let VideoSrc;
    let VideoThumb;
    let VideoTrackLabel;
    switch (video) {
      case "BlackWomenInHIV":
        VideoThumb = "../../recognizing-black-women-leaders-hero.jpg";
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917245608/rendition/1080p/file.mp4?loc=external&signature=b1bc00a98fc83e32cbd431965f8668c55594be5f5a3ba160cd26e95cfa41c648";
        VideoTrackLabel = "Celebrating Black Women in HIV";
        break;
    }
    this.setState({
      show: true,
      VideoThumb: VideoThumb,
      VideoSrc: VideoSrc,
      VideoTrackLabel: VideoTrackLabel,
    });
    //this.handleShow();
  };
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container fluid className={`hero ${this.props.page}`} />

        <Container className={`${this.props.page} px-0`}>
          <Row>
            <Col xs={10} className="m-auto px-0">
              <div className={`hero-callout muted-yellow-dark`}>
                
                <h1 className="title">
                  <span className="sub-title d-block">From poetry to presidents:</span>
                  Shifting Perceptions of HIV
                  <br className="d-none d-lg-block" /> Through Activism and
                  Artistry{" "}
                </h1>
              </div>
               <p class="published-cta text-uppercase">Originally published November 11, 2023 <span>| 1-MINUTE read</span></p>
              <img
                onClick={() => this.handleClick("BlackWomenInHIV")}
                src={"/kayla-quimbley-video-thumbnail.jpg"}
                alt="Kayla Quimbley Thumbnail"
                className="video thumbnail w-100 mt-4"
              />
              <p className="intro">
                Kayla Quimbley is a poet with a powerful perspective—she was
                born with HIV. Now, as the youngest member of the Presidential
                Advisory Council on HIV/AIDS, she has become a fierce advocate
                for people who acquired HIV through vertical transmission
                (passed from mother to child). Kayla spoke on her lived
                experience in a performance during the Gilead plenary session at
                USCHA 2023. Kayla is shifting perspectives, challenging the
                narratives that surround HIV, and showing people what it means
                to live and thrive with HIV through her artistry. Check out her
                performance in the video above and learn more about her personal
                story <a href="/championsofchange/kayla">here</a> to discover
                how her strength and courage helped her become a champion of
                HIV&nbsp;advocacy.
              </p>

              <HTML5Video
                handleClose={this.handleClose}
                show={this.state.show}
                url={this.state.VideoSrc}
                videoTrackLabel={this.state.VideoTrackLabel}
                closedCaptions={ClosedCaptions}
              />

              <h2 className="title mt-4 pt-3">Related Content</h2>

              <Container className="related-content section mt-2 pt-1 mb-standard">
                <Row>
                  <Col xs={12} className="m-auto">
                    <Container>
                      <Row className="justify-content-lg-between">
                      <div class="card-callout mb-3 mb-lg-0 col-lg-4 col-12">
                        <a class="d-flex flex-column justify-content-between" href="../../championsofchange/kayla">
                          <div>
                            <h3>Kayla Quimbley: A Passionate Poet Challenging the
                            Narrative</h3>
                            <p class="title-one-line">Born with HIV, Kayla is a poet who uses her powerful perspective to advocate for others and show people what it means to thrive with HIV. Learn more about Kayla—one of our Champions of Change honorees—and how she's using her lived experience to shatter
                            narratives around HIV.</p>
                          </div>
                            <span><span>Learn more</span>&nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                        </a>
                      </div>

                      <div class="card-callout mb-3 mb-lg-0 col-lg-4 col-12">
                        <a class="d-flex flex-column justify-content-between" href="/championsofchange">
                          <div>
                            <h3>Champions of Change: A Celebration of Black Women
                            Changemakers in HIV</h3>
                            <p class="title-one-line">A tribute celebrating 10 inspirational Black women leaders in the HIV movement whose trailblazing work has contributed to changing the course of the HIV epidemic and paved the way for future&nbsp;leaders.</p>
                          </div>
                            <span><span>Learn more</span>&nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                        </a>
                      </div>

                      <div class="card-callout mb-3 mb-lg-0 col-lg-4 col-12">
                        <a class="d-flex flex-column justify-content-between" href="../../deeper-dive/dazon">
                          <div>
                            <h3>Empowering Black Women in the American South to Protect Their Sexual Health</h3>
                            <p class="title-one-line">Learn about the work Dázon Dixon Diallo is doing to improve access to care for women who live with and
                            are at risk for HIV.</p>
                          </div>
                            <span><span>Learn more</span>&nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                        </a>
                      </div>

                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>

              <Container className="px-0">
                <Row>
                  <Col xs={12} className="px-0 mb-4">
                    <BackToTop />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content;
